import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

// 秘钥
const aesKey = 'hj7x22H$yuBI0456';
const aesIv = 'NIfb&74GUY86Gfgh';

if(localStorage.getItem('CV') != '*'){
    localStorage.setItem('CV',1)
}

/**
 * 加密
 * @param {string} str 需要加密的数据
 * @param {string} secret 密钥
 * @returns
 */
export function encrypt(str) {
    const cryptoOption = {
        iv: CryptoJS.enc.Utf8.parse(aesIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };
    // AES-128-CBC
    const encryptedStr = CryptoJS.AES.encrypt(str, CryptoJS.enc.Utf8.parse(aesKey), cryptoOption).toString();
    return encryptedStr;
}

/**
 * 解密
 * @param {string} str 需要解密的数据
 * @param {string} secret 密钥
 * @returns
 */
export function decrypt(str) {
    const cryptoOption = {
        iv: CryptoJS.enc.Utf8.parse(aesIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };
    const decryptedStr = CryptoJS.AES.decrypt(str, CryptoJS.enc.Utf8.parse(aesKey), cryptoOption).toString(
        CryptoJS.enc.Utf8,
    );
    return decryptedStr;
}

// 接口加密
export function apiParamsCrypto(config){
    if((!localStorage.getItem('CV') && localStorage.getItem('CV')!=0) || localStorage.getItem('CV')=='' || localStorage.getItem('CV')=='undefined' || localStorage.getItem('CV')=='null' || localStorage.getItem('CV')=='*'){
        return
    }
    config.headers['crytoVer'] = localStorage.getItem('CV')
    // 是不是重试的请求
    let replaceFlag =  (config.hasOwnProperty('replaceFlag') && config.replaceFlag)
    // 如果是，将请求的参数从加密状态还原
    if(replaceFlag){
        try {
            let encodedString = config.data.split('data=')[1]
            let firstDecodedString = decodeURIComponent(encodedString);
            let finalDecodedString = decodeURIComponent(firstDecodedString);
            let queryString = decrypt(finalDecodedString)
            let params = new URLSearchParams(queryString);
            let queryParams = {};
            params.forEach(function(value, key) {
                queryParams[key] = value || null;
            });
            config.data = queryParams
            console.log(config,config.data,'qinhaixin');
        } catch(error){
            console.log(error);
        }
    }
    if (config.method === 'post') {
        // 加密逻辑 不加密则Content-Type为空，加密分为两种键值对和json分开加密；目前有文件的导入和app端接口不加密，其余加密
        function fomateDate(data) {
            var str = [];
            for (var p in data) {
                str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
            }
            return str.join("&");
        }
        if (config.headers['Content-Type'] ) {

            console.log(config.data,"加密前的参数")

            if(config.headers['Content-Type'].includes("application/x-www-form-urlencoded")){
                config.data = {
                    data: encrypt(fomateDate(config.data))
                }
            }
            if(config.headers['Content-Type'].includes("application/json")){
                config.data = {
                    data: encrypt(JSON.stringify(config.data))
                }
            }

            config.params = {
                data: encrypt(fomateDate({timeStamp: new Date().getTime(),requestId: uuidv4()}))
            }

        }
    }
    if (config.method === 'get') {
        config.params = {
            data: encrypt(JSON.stringify({
                ...config.params,
                timeStamp: new Date().getTime(),
                requestId: uuidv4()
            }))
        }
    }
}
// 接口解密
export function responseDataDecrypt(response){
    // 后端返回code == 406标识加密版本不一致
    if(response.data.hasOwnProperty('code') && response.data.code == 406){
        // 读取最新的加密版本
        let reqCrytover = response.headers['crytover'] || '*'
        // 存储最新的加密版本
        localStorage.setItem('CV',reqCrytover)
        // 重新刷新页面（注释掉，变为软刷新）
        // window.location.reload()
    }else{ // 如果不是406标识请求一致
        if((!localStorage.getItem('CV') && localStorage.getItem('CV')!=0) || localStorage.getItem('CV')=='' || localStorage.getItem('CV')=='undefined' || localStorage.getItem('CV')=='null' || localStorage.getItem('CV')=='*'){
            return
        }
        // 如果是加密版本会有response.data.data，否则就是response.data
        if(response.data && response.data.data){
            try{
                response.data = JSON.parse(decrypt(response.data.data))
            }
            catch(error){}
            console.log(response.data,'解密后的数据');
        }
    }
}
