/*
 * @Author: XTTD-2022VEOKDX\Administrator 2924403764@qq.com
 * @Date: 2022-10-19 10:25:35
 * @LastEditors: Qinhaixin 2924403764@qq.com
 * @LastEditTime: 2022-10-19 12:21:59
 * @FilePath: \solax-cloud-new\src\API\axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import router from '../router'
//进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { apiParamsCrypto, responseDataDecrypt } from './crypto';
import { preventRepeat } from './header'
let lan = {  t:(lang)=> window.i18n.t(lang) }

let replaceCount = 0

// axios.defaults.baseURL = 'https://47p92600f9.imdo.co/'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.timeout = 80000 // 设置请求超时时间

// 错误提示函数(采用节流是因为防止多次提示)
let timeOutT = null
function errorTipFun(msg) {
    clearTimeout(timeOutT)
    timeOutT = setTimeout(() => {
        Message({
            type: 'error',
            message: msg
        })
    }, 10)
}
function returnUrlGrayscaleMark() {
    let urlBefore = ''
    let locationHref = location.href
    let locationOriginLength = location.origin.length + 1
    let locationHashLength = location.hash.length
    if (locationHashLength + locationOriginLength != locationHref.length) {
        let deleteOrigin = location.href.replace(location.origin + '/', '')
        let deleteHash = deleteOrigin.replace(location.hash, '')
        if (deleteHash.length > 1) {
            urlBefore = deleteHash.substring(0, deleteHash.indexOf('/'))
        }
    }
    return urlBefore
}

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 请求头中写入token
        if (window.sessionStorage.getItem('token')) {
            config.headers['token'] = window.sessionStorage.getItem('token');
            // 登录的情况下，判断是否含有灰度标识，如果有就需要在后面每一次phoebus请求前面加上灰度标识
            let webPath = sessionStorage.getItem('webPath') || false
            if(webPath && webPath!='null'){
                let phoebusIndex = config.url.indexOf('/phoebus')
                if(phoebusIndex != -1){ // 必须是/phoebus
                    let grayscaleMark = returnUrlGrayscaleMark() || false // 获取用户地址栏的灰度标识
                    let url = config.url.substr(phoebusIndex,config.url.length) // 获取每一次请求的接口地址
                    // 如果用户的地址栏中没有灰度标识或者地址栏中的灰度标识与本地存储的标识一致，并且是登录页面时，使用本地存储的标识
                    let pageRouter = router.history.current.path
                    if(!grayscaleMark || (grayscaleMark == sessionStorage.getItem('webPath')) || pageRouter == '/login' || pageRouter == '/active_sc' || pageRouter == '/board'){
                        config.url = `/${sessionStorage.getItem('webPath')}${url}`
                    }else{ // 如果地址栏中存在灰度标识，且与本地的标识不一致时使用地址栏的标识，目的是防止用户篡改地址栏标识
                        config.url = `/${grayscaleMark}${url}`
                    }
                }
            }
            // 如果不是请求app的接口，需要携带灰度标识
            let proxyAppReg = /proxyApp/
            let proxyReg = /proxy/
            let appApiReg = /app_api/
            if (!proxyAppReg.test(config.url) && !proxyReg.test(config.url) && !appApiReg.test(config.url)) {
                if (sessionStorage.getItem('versionKey') && sessionStorage.getItem('versionVal') && sessionStorage.getItem('versionVal') != 'null') {
                    config.headers[sessionStorage.getItem('versionKey')] = sessionStorage.getItem('versionVal');
                }
            }
        }
        // 请求头中写入lang
        if (window.localStorage.getItem('lang')) {
            config.headers['lang'] = window.localStorage.getItem('lang') || 'zh_CN'
        }
        let _preventRepeat = new preventRepeat()
        if(_preventRepeat){
            for(let key in _preventRepeat){
                config.headers[key] = _preventRepeat[key]
            }
        }
        // 入参加密
        apiParamsCrypto(config)
        NProgress.start()
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 接口解密
        responseDataDecrypt(response)
        if(response.data.hasOwnProperty('code') && response.data.code == 406 && replaceCount <= 10){
            ++replaceCount // 重试机制新增值，避免进入死循环
            response.config.replaceFlag = true // 在接下来的请求中标记为重试的请求
            response.config.headers = JSON.parse(JSON.stringify(response.config.headers))
            return axios.request(response.config) // 重新发起一个请求
        }else { 
            replaceCount = 0
        }
        // 判断token有没有失效
        if (response.data && response.data.code && response.data.code == '403' // token失效
            || response.data && response.data.code && response.data.code == '401' // 没有权限
        ) {
            // 失效之后清除数据，返回登录页
            if (response.data.code == '403') {
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('loginTime')
                sessionStorage.removeItem('userObj')
                errorTipFun(lan.t('Login_expired_please_log_in_again'))
                sessionStorage.removeItem('versionKey')
                sessionStorage.removeItem('versionVal')
                sessionStorage.removeItem('webPath')
                router.push('/login')
            } else if (response.data.code == '401') {
                // 重新保存灰度标识，并重定向到指定版本的登录页面
                let replaceHeader = null
                if (response.data.hasOwnProperty('data')) {
                    replaceHeader = response.data.data
                }
                if (response.data.data.hasOwnProperty('versionKey')) {
                    // 重置请求头标识
                    if (replaceHeader.versionKey) {
                        sessionStorage.setItem('versionKey', replaceHeader.versionKey)
                    }
                    // 重置请求头内容
                    if (replaceHeader.versionVal) {
                        sessionStorage.setItem('versionVal', replaceHeader.versionVal)
                    }
                    // 重置版本路径
                    if (replaceHeader.webPath) {
                        sessionStorage.setItem('webPath', replaceHeader.webPath)
                        localStorage.setItem('webPath', replaceHeader.webPath)
                        errorTipFun(lan.t('系统已更新!请重新登录，使用新版本。'))
                        let url = `${location.protocol}//${location.host}/${replaceHeader.webPath}/#/login?replace=1`
                        window.location.replace(url)
                    } else {
                        errorTipFun(lan.t('系统已更新!请重新登录，使用新版本。'))
                        let url = `${location.protocol}//${location.host}/#/login?replace=1`
                        window.location.replace(url)
                        localStorage.removeItem('webPath')
                        sessionStorage.removeItem('webPath')
                    }
                } else {
                    sessionStorage.removeItem('versionKey')
                    sessionStorage.removeItem('versionVal')
                    sessionStorage.removeItem('webPath')
                    localStorage.removeItem('webPath')
                }
            }
        }
        NProgress.done()
        return response
    },
    error => {
        if (error.code == 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
            errorTipFun(lan.t('TimeOut'))
        }
        return Promise.reject(error)
    }
)

let AJAX = axios.create()

//设置——基地址
// let base = process.env.VUE_APP_BASE_APISet
// let base2 = process.env.VUE_APP_BASE_APISet2
let base = `${location.protocol}//${location.host}/app_api`
// let base = `http://10.5.9.145:8022/app_api`
let base2 = `${location.protocol}//${location.host}/app_api`
export const POST = {
    // post_set 加密 (proxy)
    post_set: (url, data, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url: `${base}${url}`,
                method: 'post',
                data,
                params,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers },
                transformRequest: [function (data) {
                    var str = [];
                    for (var p in data) {
                        str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
                    }
                    return str.join("&");
                }],
            })
                .then(res => {
                    response(res.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    post_set_new: (url, data, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url,
                method: 'post',
                data,
                params,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers },
                transformRequest: [function (data) {
                    var str = [];
                    for (var p in data) {
                        str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
                    }
                    return str.join("&");
                }],
            })
                .then(res => {
                    response(res.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    // 表单格式的post请求
    post_form: (url, data, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: 'post',
                data,
                params,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers },
                transformRequest: [function (data) {
                    var str = [];
                    for (var p in data) {
                        str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
                    }
                    return str.join("&");
                }],
            })
                .then(res => {
                    response(res.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    // 文件上传的post请求 加密
    post_file: (url, data, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: 'post',
                data,
                params,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                transformRequest: [function (data) { // 将数据转换为formdata类型的数据
                    // var formData = new FormData()
                    // for (var key in data) {
                    //     formData.append(key, data[key])
                    // }
                    // return formData;

                  var str = [];
                  for (var p in data) {
                    str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
                  }
                  return str.join("&");
                }],
            })
                .then(res => {
                    response(res.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    //  导入专用 不加密
    post_file1: (url, data, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: 'post',
                data,
                params,
                // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                transformRequest: [function (data) { // 将数据转换为formdata类型的数据
                    var formData = new FormData()
                    for (var key in data) {
                        formData.append(key, data[key])
                    }
                    return formData;
                }],
            })
                .then(res => {
                    response(res.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    // 下载文件的post请求
    post_down: (url, data, fileName, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: 'post',
                data,
                params,
                headers: { 'lang': localStorage.getItem('lan'), ...headers },
                responseType: 'blob',
            })
                .then(res => {
                    if (res.status === 200 && res.data) {
                        let urls = window.URL.createObjectURL(res.data)
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = urls
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                        response(true)
                    } else {
                        response(false)
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    // post json格式 加密
    post_json:(url, data, params, headers) => {
      // debugger
        return new Promise((response, reject) => {
          axios({
            url: url,
            method: 'post',
            data,
            params,
            headers: { 'Content-Type': 'application/json' },
            // transformRequest: [function (data) {
            //   var str = [];
            //   for (var p in data) {
            //     str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
            //   }
            //   return str.join("&");
            // }],
          })
            .then(res => {
              response(res.data);
            })
            .catch(error => {
              reject(error);
            })
        })
    },
    // post将参数放在url中请求
    post_url: (url, params) => {
        return new Promise((response, reject) => {
            axios.post(url, null, { params })
                .then(res => {
                    response(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    POST2: (code, url, params) => {
        let domain = base;
        if (code == 1) {
            domain = base;
        } else {
            domain = base2;
            console.log(`${domain}${url}`, "`${domain}${url}`");
        }
        return new Promise((resolve, reject) => {
            axios.post(`${domain}${url}`, params,
                // 将axios 对象obj数据的post请求转化为formdata格式
                {
                    transformRequest: [function (data) {
                        var str = [];
                        for (var p in data) {
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
                        }
                        return str.join("&");
                    }],
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    timeout: 10000
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // proxyApp 加密
    POST4: (url, params) => {
        url = url.split('.')[0]
        let base = `${location.protocol}//${location.host}/app_api`
        // let base = `http://10.5.9.145:8022/app_api`
        return new Promise((resolve, reject) => {
            axios.post(`${base}${url}`, params,
                // 将axios 对象obj数据的post请求转化为formdata格式
                {
                    transformRequest: [function (data) {
                        var str = [];
                        for (var p in data) {
                            str.push(encodeURIComponent(p) + "=" + (data[p] === null || data[p] === undefined ? '' : encodeURIComponent(data[p])));
                        }
                        return str.join("&");
                    }],
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    timeout: 800000
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // proxyApp 加密
    POST5: (url, params) => {
        url = url.split('.')[0]
        return new Promise((resolve, reject) => {
            axios.post(`/app_api${url}`, params,
                {
                    data: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json',
                        // 'token': sessionStorage.getItem('token') || ''
                    },
                    timeout: 800000
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error)
            })
        })
    },
    post_json_v6 :(url, data, params)=>{
        return new Promise((response, reject) => {
            axios({
              url: url,
              method: 'post',
              data,
              params,
              headers: { 
                'Content-Type': 'application/json',
                'websiteType':"0",
                'source':'0',
                'platform':'1',
                'deviceType':'3'
             },
            })
              .then(res => {
                response(res.data);
              })
              .catch(error => {
                reject(error);
              })
          })
    }
}

export const GET = {
    // 普通ge请求
    get: (url, params, headers) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                params,
                headers,
                method: "get"
            })
                .then(res => {
                    response(res.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    // 适应/sys/org/{id}接口形式的get请求
    get_id: (url, params) => {
        return new Promise((response, reject) => {
            axios.get(url + params)
                .then(res => {
                    response(res.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    // get文件下载请求
    get_down: (url, params, fileName) => {
        return new Promise((response, reject) => {
            axios({
                url: url + '/' + params,
                method: 'get',
                responseType: 'blob',
            })
                .then(res => {
                    if (res.status === 200 && res.data) {
                        let urls = window.URL.createObjectURL(res.data)
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = urls
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                        response(true)
                    } else {
                        response(false)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    // 获取html
    get_html: (url, params) => {
        return new Promise((response, reject) => {
            axios.get(`/phoebusHtml${url}`, { params })
                .then(res => {
                    response(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    // 获取html
    get_htmlDom: (url, params) => {
        return new Promise((response, reject) => {
            axios.get(`/phoebusHtml${url}`, { params })
                .then(res => {
                    response(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getEcharts: (url) => {
        return new Promise((response, reject) => {
            axios.get(url)
                .then(res => {
                    response(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    userCenter: (url, params, headers) => {
        return new Promise((response, reject) => {
            AJAX({
                url: `${process.env.VUE_APP_BASE_USERCENTER_SERVERURL}/unionUser${url}`,
                params,
                headers:{
                    lang:window.localStorage.getItem('lang') || 'zh_CN',
                    deviceType:'3'
                },
                method: "get",
                // timeout:500,
                mode: 'cors'
            })
            .then(res => {
                response(res.data)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

}

export const DELETE = {
    // delete请求封装 对应一下这种接口形式/sys/org/{id}
    delete_ID: (url, params) => {
        return new Promise((response, reject) => {
            axios.delete(url + params)
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // delete请求封装 对应将参数写入url的形式
    delete_URL: (url, params) => {
        return new Promise((response, reject) => {
            axios.delete(url, { params: params })
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // delete请求封装 对应将参数写入body内的形式
    delete_POST: (url, params) => {
        return new Promise((response, reject) => {
            axios.delete(url, { data: params })
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // delete 模仿post表单格式请求
    delete_POST_FORM: (url, params) => {
        return new Promise((response, reject) => {
            axios.delete(url,
                {
                    data: params,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    transformRequest: [function (data) { // 将数据转换为formdata类型的数据
                        var formData = new FormData()
                        for (var key in data) {
                            formData.append(key, data[key])
                        }
                        return formData;
                    }],
                }
            )
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // delete json格式请求
    delete_JSON: (url, params) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: "delete",
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, { indices: false })
                }
            })
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export const PATCH = {
    // patch请求封装
    Patch: (url, data) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: "patch",
                data
            })
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export const PUT = {
    // put 普通请求
    put: (url, params) => {
        return new Promise((response, reject) => {
            axios({
                url: url,
                method: "put",
                params
            })
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // put 将参数放入body种的封装
    put_body: (url, params) => {
        return new Promise((response, reject) => {
            axios.put(url, params)
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    put_query: (url, params) => {
        return new Promise((response, reject) => {
            axios.put(`${url}${params}`)
                .then(res => {
                    response(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export {
    axios
}
